import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ["modal", "carouselImage", "thumbnail"];
  
  connect() {
    this.currentIndex = 0;
    this.images = this.thumbnailTargets.map((thumbnail) =>
      thumbnail.getAttribute("data-gallery-image-url")
    );
    this.keyHandler = this.handleKeyPress.bind(this);
  }

  openModal(event) {
    this.currentIndex = this.thumbnailTargets.indexOf(event.currentTarget);
    this.updateCarouselImage();
    this.modalTarget.classList.remove("hidden");
    document.addEventListener("keydown", this.keyHandler);
  }

/*
//Original code
  closeModal(event) {
    if (event.target === this.modalTarget) {
      this.modalTarget.classList.add("hidden");
    }
  }
*/


closeModal() {
  // Hide modal
  this.modalTarget.classList.add("hidden");
  // Clear image src
  //this.modalImageTarget.src = "";
  document.removeEventListener("keydown", this.keyHandler);
}

  preventClose(event) {
    event.stopPropagation();
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.updateCarouselImage();
  }

  prevImage() {
    this.currentIndex = 
      (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.updateCarouselImage();
  }

  updateCarouselImage() {
    this.carouselImageTarget.src = this.images[this.currentIndex];
  }


  handleKeyPress(event) {
    if (event.key === "ArrowRight") {
      this.nextImage();
    } else if (event.key === "ArrowLeft") {
      this.prevImage();
    } else if (event.key === "Escape") {
      this.closeModal();
    }
  }

}