/**
 * @license
 * Copyright 2019 Google LLC. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
// This example adds a map with markers, using web components.
async function initMap() {
    console.log("Maps JavaScript API loaded.");
  }
  
  window.initMap = initMap;